import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { Footer } from './footer';
import { Box, styled } from '@mui/system';
import { Header } from './header';
import Snowfall from 'react-snowfall';
import hmm_christmas from './images/hmm_christmas.png';
import Button from './Button';

function App() {
  const [uid, setuid] = useState('');
  const [address, setaddress] = useState('');
  const [isClaimed, setIsClaimed] = useState(0);

  useEffect(() => {
    const u = window.location.pathname;
    console.log(u.length)
    if(u.length != 33) {
      window.location.href = 'https://app.tigris.trade';
    } else {
      fetch(`https://gift-gmbn6.ondigitalocean.app/check${u}`).then((response) => {
        console.log(response)
        response.json().then((data) => {
          setaddress(data.address);
        });
      });

      setuid(u.substring(1));
    }
  }, []);

  function claim() {
    if(isClaimed == 2) {
      window.location.href = 'https://app.tigris.trade';
    } else {
      fetch(`https://gift-gmbn6.ondigitalocean.app/claim_gift/${uid}`).then((response) => {
        response.json().then((data) => {
          console.log(data);
          setIsClaimed(2);
        });
      });
    }
  }

  return (
    <>
      <div className={"beauty"}/>
      <Snowfall
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          zIndex: '1000'
        }}
      />
      <Header />
      <Wrapper style={{background: 'rgb(20, 20, 22)'}} >
        <div>
          <div style={{padding: '70px', textAlign: 'center', color: 'white'}}>
            <div style={{maxWidth: '500px', textAlign: 'center', margin: 'auto'}}>
              <img src={hmm_christmas} style={{width: '200px'}}/>
              <h1>Someone sent you a gift!</h1>
              <p style={{color: 'rgb(210, 206, 222)'}}>Because of the holiday season, we at Tigris are giving free $1k BTC long positions to our users and giving them one more to gift, and you have been gifted!</p>
              <p style={{color: 'rgb(210, 206, 222)'}}>Currently, Tigris is the fastest leverage trading platform, with great UX, lowest slippage possible, advanced position management. But Tigris is changing, we have huge plans to expand Tigris and we invite you to read more about our vision <span><a style={{color: 'blue'}} href='https://mirror.xyz/haz.eth/EtolVQwdjv0J5flpeMyfMylEVZbrkTGd_YH7hmc_Ymk' target='_blank'>here</a></span></p>
              <p style={{color: 'rgb(210, 206, 222)'}}>Once you click the button below you will get your position opened at the current price to the address below, use Tigris app to manage it.</p>
              <b>{address}</b>
              <Button onClick={() => {claim(); setIsClaimed(1)}}><b>{isClaimed == 0 ? "CLAIM POSITION" : isClaimed == 1 ? "Claiming..." : "Open Tigris To Manage"}</b></Button>
            </div>
          </div>
        </div>
        
      </Wrapper>
      <Footer />
    </>
  );
}

const Wrapper = styled(Box)(({ theme }) => ({
  minHeight: 'calc(100vh - 110px)'
}));

export default App;
