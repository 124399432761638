import { Box, Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { Container } from './Container';

export const Footer = () => {
  return (
    <FooterContainer>
      <Container>
        <FooterWrapper>
          <FooterInfo>
          </FooterInfo>
          <Line />
          <FooterNav>
            <NavLinks>
              <SmallText sx={{ cursor: 'pointer' }} onClick={() => window.open('https://docs.tigris.trade', '_blank')}>
                Docs
              </SmallText>
              <SmallText
                sx={{ cursor: 'pointer' }}
                onClick={() => window.open('https://discord.gg/9YqkK29Qx2', '_blank')}
              >
                Discord
              </SmallText>
              <SmallText
                sx={{ cursor: 'pointer' }}
                onClick={() => window.open('https://twitter.com/tigristrades', '_blank')}
              >
                Twitter
              </SmallText>
            </NavLinks>
            <SmallText>© 2023. All rights reserved</SmallText>
          </FooterNav>
        </FooterWrapper>
      </Container>
    </FooterContainer>
  );
};

const FooterContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  minHeight: '50px',
  backgroundColor: '#18191D',
  alignItems: 'center',
  marginTop: '0px'
}));

const FooterWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column'
  }
}));

const FooterInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '2rem',
  minHeight: '50px',
  alignItems: 'center',
  marginRight: '2%',
  marginLeft: '2%',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'space-between'
  },
  [theme.breakpoints.down('xs')]: {
    gap: '10px'
  }
}));

const TextCoin = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '5px',
  alignItems: 'center'
}));

const Text = styled(Box)(({ theme }) => ({
  fontWeight: '500',
  fontSize: '12px',
  lineHeight: '20px',
  [theme.breakpoints.down('xs')]: {
    fontSize: '10px'
  }
}));

const SmallText = styled(Box)(({ theme }) => ({
  color: '#B1B5C3',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '20px',
  textAlign: 'center',
  [theme.breakpoints.down('xs')]: {
    fontSize: '10px'
  }
}));

const GasFee = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '8px',
  alignItems: 'center'
}));

const FooterNav = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '1rem',
  minHeight: '50px',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginRight: '2%',
  marginLeft: '2%'
}));

const NavLinks = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '1rem'
}));

const Line = styled(Divider)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'block'
  }
}));
