import React from 'react';
import './Button.css';

type ButtonProps = {
    onClick: () => void; // You can specify a more detailed type for the event if needed
    children: React.ReactNode;
  };

const Button: React.FC<ButtonProps> = ({ onClick, children }) => {
  return (
    <button className="market-button" onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;