import React, { useEffect, useState } from 'react';
import { Avatar, Badge, Box, Button, Divider, IconButton, Modal } from '@mui/material';
import { styled } from '@mui/system';
import { Container } from './Container';
import FullLogo from './images/fullLogo.svg';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { NotificationsNone, Person, Dehaze, Search, Close, Translate, NavigateBefore } from '@mui/icons-material';

export const Header = () => {
  const style = {
    position: 'absolute',
    top: '0',
    left: '0',
    width: 340,
    maxHeight: '100vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'auto'
  };

  return (
    <>
      <HeaderContainer>
        <Container>
          <ContainerWrapper>
            <TigrisLogo>
              <Img src={FullLogo} alt="tigris-logo" />
            </TigrisLogo>
            <ActiveBar>
              <MobileTab>
                <Dehaze />
              </MobileTab>
              <Actions>
                <XTigTabWrapper>
                </XTigTabWrapper> 
              </Actions>
            </ActiveBar>
            <MobileActiveBar>
              <IconButton aria-label="alarm" component="label">
                <Search />
              </IconButton>

              <IconButton aria-label="alarm" component="label">
                <Dehaze />
              </IconButton>
            </MobileActiveBar>
          </ContainerWrapper>
        </Container>
      </HeaderContainer>
    </>
  );
};

const HeaderContainer = styled(Box)({
  height: '60px',
  borderBottom: '1px solid #2E2E30',
  backgroundColor: '#141416'
});

const ContainerWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  justifyContent: 'inherit',
}));

const TigrisLogo = styled(Box)({
  width: '200px',
  height: 'auto',
  cursor: 'pointer',
  marginBottom: '-5px'
});

const Img = styled('img')({
  width: 'auto',
  height: '30px'
});

const ActiveBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  height: '100%',
}));

const MobileTab = styled(IconButton)(({ theme }) => ({
  width: 40,
  height: 40,
  display: 'none',
  [theme.breakpoints.down(1280)]: {
    display: 'block'
  }
}));

const TabContainer = styled(Box)(({ theme }) => ({
  borderBottom: 1,
  borderColor: 'divider',
  [theme.breakpoints.down(1280)]: {
    display: 'none'
  }
}));

const CustomTab = styled(Tab)({
  color: '#ffffff',
  textTransform: 'none',
  disableRipple: 'true',
  disableFocusRipple: 'true'
});

const Actions = styled(Box)({
  display: 'flex',
  alignItems: 'center'
});

const MobileActiveBar = styled(Box)(({ theme }) => ({
  display: 'none',
}));

const ShellButton = styled(Button)(({ theme }) => ({
  border: '1px solid #FFFFFF',
  background: '#191B1F',
  borderRadius: '0px',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  padding: 9,
  marginRight: '12px'
}));

const MobileShellButton = styled(Button)(({ theme }) => ({
  border: '1px solid #FFFFFF',
  borderRadius: '0px',
  display: 'flex',
  alignItems: 'center',
  gap: '8px'
}));

const GasAmount = styled(Box)(({ theme }) => ({
  fontWeight: '500',
  fontSize: '15px',
  lineHeight: '14px'
}));

const ModalContainer = styled(Box)(({ theme }) => ({
  padding: '1rem 1.5rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem'
}));

const XTigTabWrapper = styled(Box)(({ theme }) => ({
  alignItems: 'center'
}));

const TranslateDropDown = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  marginLeft: '10px',
  cursor: 'pointer'
}));

const FlagName = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px'
}));

const FlagButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  cursor: 'pointer'
}));
